<template>
  <v-menu
    v-model="model"
    :close-on-content-click="false"
    offset-x
    :max-width="minWidth"
    :min-width="minWidth"
    :position-x="positionX"
    :position-y="positionY"
    content-class="move-to"
  >
    <v-card
      flat
      :elevation="0"
    >
      <v-toolbar
        color="#f1f1f1"
        flat
        height="48"
      >
        <div
          class="mr-3"
          style="min-width: 24px;"
        >
          <!-- <pre>
          {{ activeFolderData }}
          </pre> -->
          <v-icon
            @click="onClickBack"
            :disabled="inProcess || this.activeFolderData && this.activeFolderData.id === 'home'"
          >
            mdi-chevron-left
          </v-icon>
        </div>
        <v-toolbar-title
          class="font-weight-medium"
          style="font-size: 16px;"
          v-if="activeFolderData"
        >
          {{ activeFolderData.title || (activeFolderData.info && activeFolderData.info.title || 'Untitled') }}
        </v-toolbar-title>
        <v-spacer />
        <v-icon
          size="18"
          @click="model = false"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-divider />
      <div
        class="custom-scroll move-to__container d-flex flex-column"
        style="max-height: 250px; min-height: 250px; overflow: auto;"
      >
        <template
          v-if='!inProcess'
        >
          <v-list
            v-if="activeFolderChildren.length"
            flat    
          >
            <v-list-item-group
              color="primary"
              v-model="pickedFolder"
            >
              <v-list-item
                :ripple="false"
                v-for="(folderChildren, index) in activeFolderChildren"
                :key="`folder-children-${folderChildren.docId}-${index}`"
                @dblclick="() => onDbClick(folderChildren)"
                color="primary"
                style="padding-right: 8px;"
                :value="folderChildren.docId"
              >
                <v-list-item-icon>
                  <v-icon>
                    mdi-folder
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ folderChildren.info.title || 'Untitled' }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-tooltip
                  bottom
                  transition="fade-transition"
                  left
                >
                  <template v-slot:activator="{on, attrs}">
                    <v-icon
                      size="24"
                      :color="folderChildren.docId === pickedFolder ? 'primary' : 'inherit'"
                      v-on="on"
                      @click.stop="() => onDbClick(folderChildren)"
                      v-bind="attrs"
                    >
                      mdi-chevron-right
                    </v-icon>
                  </template>
                  {{ `Открыть папку ${folderChildren.info.title || 'Untitled'}` }}
                </v-tooltip>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-else>
            <v-subheader class="d-flex align-self-center justify-self-center flex-grow-1 grey--text lighten-3">Пустая папка</v-subheader>
          </template>
        </template>
        <div v-else style="height: 100%; width: 100%;" class="d-flex flex-column align-center justify-center flex-grow-1">
          <v-progress-circular 
            indeterminate
            color="primary"
            width="2"
          />
        </div>
      </div>
      <v-divider />
      <v-card-actions>
        <v-icon
          class="ml-2"
          :disabled="!!pickedFolder"
          @click="onClickCreateFolder"
        >
          mdi-folder-plus
        </v-icon>
        <v-spacer />
        <v-btn
          v-if="pickedFolder"
          depressed
          @click="() => moveTo(pickedFolder)"
          :disabled="disabledBtn"
          color="primary"
          height="32"
        >
          {{ 'Переместить' }} 
        </v-btn>
        <v-btn
          v-else
          depressed
          @click="() => moveTo()"
          :disabled="disabledBtn"
          color="primary"
          height="32"
        >
          Переместить в эту папку
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { getFolderById, getFoldersByParentId } from '@/fb'
import { findTreeNode } from '@/api/functions'

export default {
  props: {
    inAddFolderProcess: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'MoveToDialog',
  data: () => ({
    moveToInProcess: false,
    inProcess: false,
    pickedFolder: null,
    positionX: null,
    positionY: null,
    minWidth: 350,
    activeFolder: undefined,
    activeFolderData: null,
    activeFolderChildren: []
  }),
  created() {
    this.activeFolder = this.$store.getters.activeFolder && this.$store.getters.activeFolder[0] && this.$store.getters.activeFolder[0] !== 'home' ? this.$store.getters.activeFolder[0] : null
    const { el } = this.dialogData
    const rect = el.getBoundingClientRect()
    const { x, y, width, height } = rect
    
    this.positionX = x
    this.positionY = y + height + 10
    this.minWidth = width > this.minWidth ? width : this.minWidth
  },
  computed: {
    iconsColor() {

      return 'primary'
    },
    disabledBtn() {
      const { parentId = null } = this.$store.getters.moveToDialogTarget || {}
      return (this.activeFolder === parentId && !this.pickedFolder) ||  this.inProcess
    },
    currentActiveFolderData() {
      const [activeFolderId] = this.activeFolder
      const result = this.$store.getters.virtualTree.reduce((acc, item) => {
        acc = [...acc, ...item.items]
        return acc
      }, [])
      const virtualTreeIds = result.map(item => item.id)
      let data
      if (virtualTreeIds.includes(activeFolderId)) {
        data = result.find(item => item.id === activeFolderId)
      } else {
        data = null
      }

      const response = data
      return response
    },
    dialogData() {
      return this.$store.getters.moveToDialog
    },
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$store.commit('setMoveToDialogModel', val)
      }
    }
  },
  methods: {
    async moveTo(parentId = this.activeFolder) {
      this.moveToInProcess = true
      const { target: item } = this.dialogData
      const { docId } = item
      await this.$store.dispatch('editItemInFolder', {
        docId,
        data: {
          parentId
        }
      })
      this.moveToInProcess = false
      this.model = false
    },
    onClickCreateFolder() {
      const pickedFolder = this.pickedFolder && findTreeNode({ children: this.$store.getters.tree }, this.pickedFolder)
      const to = pickedFolder || this.activeFolderData
      if (to) {
        to.docId = this.activeFolder
      }
    
      this.$emit('createFolder', to)
    },
    onClickBack() {
      const parentId = this.activeFolderData && this.activeFolderData.parentId || null
      this.activeFolder = parentId || null
    },
    onDbClick(item) {
      const { docId } = item
      this.activeFolder = docId
    },
    getActiveFolderChildrens() {
      const withoutCurrentTarget = arr => {
        const { docId: targetDocId } = this.dialogData.target
        return arr.filter(f => f.docId !== targetDocId)
      }
      if (this.currentActiveFolderData) {
        const folders = [...this.$store.getters.tree]
        const result = this.currentActiveFolderData.id === 'home' ? folders : []
        return withoutCurrentTarget(result)
      }
      const [activeFolderId] = this.activeFolder
      const findedNode = findTreeNode({ children: this.$store.getters.tree }, activeFolderId)
      const result = findedNode ? findedNode.children : []
      return withoutCurrentTarget(result)
    },
    getCurrentFolderDataById() {
      const [ id ] = this.activeFolder
      const findedNode = findTreeNode({ children: this.$store.getters.tree }, id)
      return [id, findedNode]
    }
  },
  watch: {
    async inAddFolderProcess(to, prev) {
      alert()
      if (!to && prev) {
        const { docId: targetDocId } = this.dialogData.target
        this.inProcess = true
        const { docs: children } = await getFoldersByParentId(this.$store.state.user.uid, this.activeFolder).get()
        this.activeFolderChildren = children.map(ch => {
          const { id } = ch
          const data = ch.data()
          data.docId = id
          return data
        }).filter(f => f.docId !== targetDocId)
        this.inProcess = false
      }
    },
    async activeFolder(to) {
      const { docId: targetDocId } = this.dialogData.target
      this.inProcess = true
      const userId = this.$store.state.user.uid
      const toFolder = to
      const doc = toFolder && await getFolderById(userId, toFolder) || null
      const { docs: children } = await getFoldersByParentId(userId, toFolder).get()
      this.activeFolderData = toFolder && doc.data() || {
        "id": "home",
        "title": "Library",
        "multiple": true,
        "icon": "mdi-folder"
      }

      this.activeFolderChildren = children.map(ch => {
        const { id } = ch
        const data = ch.data()
        data.docId = id
        return data
      }).filter(f => f.docId !== targetDocId)
      this.pickedFolder = null
      this.inProcess = false

      //this.activeFolderData = this.currentActiveFolderData || this.getCurrentFolderDataById()[1]
    }
  }
}
</script>
<style lang="scss">
.move-to__container {
  .v-list .v-list-item--active .v-icon {
    color: inherit !important;
  }
}
</style>